@import './colour-palette.css';

.dark {
  background-color: var(--gray-12);

  /* ant component overrides */

  .ant-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--gray-9);
  }

  .ant-avatar:hover {
    border: 1px solid var(--gray-1);
  }

  .ant-drawer-header-close-only {
    padding-bottom: 16px;
  }

  &.mobile .ant-drawer-body {
    padding: 0;
  }

  .ant-card {
    border-radius: 8px;
    background-color: var(--gray-12);
    color: var(--gray-6);
  }

  .ant-typography {
    color: var(--gray-6);
  }

  .ant-typography-danger {
    color: var(--red-8);
  }

  .ant-alert-success {
    color: white;
    background-color: transparent;
    border: 1px solid var(--lime-8);
  }

  .ant-alert-warning {
    color: white;
    background-color: transparent;
    border: 1px solid var(--gold-8);
  }

  .ant-alert-error {
    color: white;
    background-color: transparent;
    border: 1px solid var(--red-8);
  }

  .ant-menu-item:active {
    background-color: var(--gray-12) !important;
  }

  .ant-menu-item-selected,
  .ant-btn,
  .ant-menu-item:hover {
    background-color: transparent;
  }

  .ant-menu-item-selected,
  .ant-btn:not(.ant-btn-default:disabled, .ant-btn-link),
  .ant-menu-item:hover {
    color: var(--gray-5) !important;
  }

  .ant-btn:not(:disabled):not(.ant-btn-disabled):hover {
    color: var(--gray-1) !important;
  }

  .ant-menu-item-disabled {
    color: var(--gray-4) !important;
  }

  .ant-menu {
    .ant-menu-submenu.sidenavButtonPressed a button {
      opacity: 1;
      background-color: var(--gray-30);
    }

    :not(.sidenavButtonPressed):not(.ant-menu-submenu-selected):not(.ant-menu-submenu-open) .ant-menu-submenu-title {
      & > i.ant-menu-submenu-arrow {
        opacity: 0.6;
      }

      &:hover > i.ant-menu-submenu-arrow {
        opacity: 1;
        background-color: var(--gray-30);
      }
    }

    :not(.sidenavButtonPressed):not(.ant-menu-submenu-selected) .ant-menu-submenu-title {
      &:hover:not(:has(span.ant-menu-title-content button:hover)) i.ant-menu-submenu-arrow {
        opacity: 1;
        background-color: var(--gray-30);
      }
    }

    :not(.ant-menu-inline-collapsed) {
      .ant-menu-submenu-title {
        & > i.ant-menu-submenu-arrow {
          border-radius: 50%;
        }
      }
    }
  }

  .ant-btn {
    border-radius: 16px;
  }

  li.ant-menu-submenu span.ant-menu-title-content a {
    color: var(--gray-1);

    &:focus {
      color: var(--gray-1);
    }

    &:hover {
      color: var(--gray-1);
    }
  }

  li.ant-menu-submenu span.ant-menu-title-content button svg {
    color: var(--gray-5);
    transition: all 0.3s;
  }

  li.ant-menu-submenu span.ant-menu-title-content button:hover {
    background-color: var(--gray-30);

    svg {
      color: var(--gray-1);
    }
  }

  li.ant-menu-submenu .ant-menu-submenu-arrow {
    opacity: 1 !important;
    color: var(--gray-5);
    transition: all 0.3s;
  }

  li.ant-menu-submenu-active .ant-menu-submenu-arrow {
    color: var(--gray-1);
  }

  li.ant-menu-submenu.sidenavButtonPressed > div,
  li.ant-menu-submenu.sidenavButtonPressed:hover > div {
    background-color: var(--blue-5);

    button {
      background-color: var(--gray-30);

      svg {
        color: var(--gray-1);
      }
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--blue-5);
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: inherit;
  }

  .ant-empty {
    color: var(--gray-1);
  }

  .ant-menu-vertical .ant-menu-submenu-selected {
    background-color: var(--blue-5);
  }

  /**
      Mobile Menu
    */

  .ant-menu-submenu-popup > ul.ant-menu {
    border-radius: 8px;
  }

  .ant-modal-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid var(--gray-10);
  }

  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-footer {
    border-top: 1px solid var(--gray-10);
  }

  .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: var(--blue-5);
  }

  .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-content:hover,
  .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-content:focus,
  .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
    background-color: var(--blue-5);
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: 1px solid var(--gray-9);
    border-radius: 2px;
  }

  .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
  }

  .ant-select-selector,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid var(--gray-9);
    border-radius: 2px;
  }

  /* --- list --- */

  .ant-list {
    background-color: transparent;
    border: 1px solid var(--gray-9);
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-list:not(.ant-select-disabled):hover {
    border-color: var(--blue-4);
    border-right-width: 1px;
  }

  .ant-list-item {
    padding: 12px;
  }

  .ant-list-split .ant-list-item {
    border-bottom: 1px solid var(--gray-10);
  }

  /* --- end list --- */

  .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled * {
    background-color: transparent !important;
    color: var(--gray-4);
  }

  .ant-dropdown-menu-item-disabled > * {
    color: var(--gray-7);
  }

  .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled:hover {
    background-color: var(--gray-12);
  }

  .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    background-color: transparent;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: var(--gray-12);
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: var(--blue-10);
  }

  /* end ant component overrides */

  /* custom components */

  .customize-menu-disable-btn > .ant-menu-item-disabled,
  .customize-dropdown-disable-btn > .ant-dropdown-menu-item-disabled {
    color: var(--gray-5) !important;
  }

  .app-header-container h1,
  .secondary-app-header-container h1 {
    color: var(--gray-1) !important;
  }

  .global-sidebar-user-info-icons > button {
    border: 0;
  }

  .react-grid-item .react-resizable-handle::after {
    border-right: 2px solid var(--gray-4);
    border-bottom: 2px solid var(--gray-4);
  }

  .hovering-button-container button,
  .hovering-button-container button:active,
  .hovering-button-container button:focus,
  .hovering-button-container button:hover {
    background-color: var(--gray-13);
    color: var(--gray-1);
  }

  /**
      Header
    */

  .mobile-header-title {
    color: var(--gray-1);
  }

  .generic-popup-menu,
  .generic-popup-menu.ant-menu-root.ant-menu-vertical {
    border: 1px solid var(--gray-10);
    box-shadow:
      0 3px 6px -4px var(--gray-22),
      0 6px 16px 0 var(--gray-23),
      0 9px 28px 8px var(--gray-25);
  }

  /* --- themes for forms, which don't work out of the box even though they're antd --- */

  /* this has no impact; it is overridden by Chrome' s autocomplete CSS! * /
        .config-schema-form .ant-input-affix-wrapper,
      .config-schema-form .ant-input-affix-wrapper input {
      background-color: rgb(31, 31, 31) !important;
      color: var(--gray-1) !important;
    }

  /* --- scrollbar colouring --- */

  *::-webkit-scrollbar {
    width: 8px;
    height: 12px;
    background: transparent;
  }

  *::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  *::-webkit-scrollbar-thumb {
    background: var(--gray-10);
    border-radius: 50px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
    border: none;
    border-radius: 50px;
  }

  /* For desktop and mobile menu */

  .ant-layout-sider::-webkit-scrollbar-track,
  .ant-drawer-body::-webkit-scrollbar-track {
    background: var(--gray-12);
  }

  *::-webkit-scrollbar-corner {
    background: transparent;
  }

  /* --- end of scrollbar colouring --- */

  .fileBadge {
    background-color: var(--gray-13);
  }

  .caption {
    color: var(--gray-1);
  }

  .generic-loading-div {
    background-color: var(--gray-13);
  }

  /* --- a11y colors fixes --- */

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--gray-5);
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--gray-5);
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: var(--gray-13);
    border-color: var(--blue-5);
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: var(--gray-13);
    border-color: var(--gray-24);
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: var(--gray-5);
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--gray-1);
  }

  .ant-collapse-header-text {
    color: var(--gray-6);
  }

  /* --- end a11y colors fix --- */

  .workflowItemsLabel {
    color: var(--gray-5);
  }

  aside.web-sider {
    button,
    a {
      color: var(--gray-1);
    }
  }

  .ant-layout-content {
    //DWT: stub - still thinking about what to do with these controls.
  }

  .ant-menu-submenu-title,
  .ant-menu-item-selected {
    color: var(--gray-1) !important;
  }

  .ant-btn-primary[disabled] {
    background-color: var(--gray-10);
    color: var(--gray-4) !important;
  }

  .ant-btn[disabled] {
    background-color: var(--gray-30);
    color: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
  }

  .ant-notification-notice {
    background-color: var(--gray-12);
  }

  .ant-badge-count,
  .ant-notification-notice-message,
  .ant-notification-notice-description {
    color: var(--gray-1) !important;
  }

  .ant-badge-count {
    background-color: var(--red-5);
  }

  ul:not(.g2-tooltip-list) {
    color: var(--gray-1);
  }

  .ant-picker {
    border: 1px solid var(--gray-9);
  }

  .ant-picker-input input {
    background-color: var(--gray-12);
    color: var(--gray-6);
    border: transparent;
  }

  .ant-collapse-content-box {
    border-top: 1px solid var(--gray-9);
  }

  // Github dark flavored syntax highlighting for .markdown-body class
  @import (less) 'github-markdown-css/github-markdown-dark.css';

  .markdown-body {
    @import (less) 'highlight.js/styles/github-dark.css';
  }

  .ant-divider-horizontal.ant-divider-with-text {
    border-top: 0 !important;
    border-top-color: var(--gray-9) !important;
  }

  .ant-notification-notice-close {
    color: var(--gray-1) !important;
  }

  .ant-modal .ant-collapse.infoCollapse {
    background-color: var(--gray-9);

    .ant-collapse-expand-icon {
      color: var(--gray-1);
    }
  }

  .infoIcon {
    border: none;
  }

  .ant-form-item-has-error .ant-picker {
    border: 1px solid var(--red-5);
  }

  .recharts-wrapper {
    .recharts-tooltip-cursor {
      fill: var(--gray-2);
    }

    .recharts-layer:not(.recharts-active-dot) .recharts-dot {
      fill: var(--gray-13);
    }

    .recharts-layer.recharts-active-dot .recharts-dot {
      stroke: var(--gray-9);
    }

    .recharts-tooltip-wrapper .recharts-default-tooltip {
      .recharts-tooltip-label {
        color: var(--gray-6);
      }

      border-color: var(--gray-9) !important;
      background-color: var(--gray-12) !important;
    }
  }

  .side-menu-bottom {
    background-color: var(--gray-12);
  }

  /* perspective dark theme fixes */

  :root & {
    --icon--color: white;
    --inactive--color: #61656e;
    --inactive--border-color: #4c505b;
    --root--background: #242526;
    --active--color: #2770a9;
    --error--color: #ff9485;
    --plugin--background: #242526;
    --overflow-hint-icon--color: rgba(255, 255, 255, 0.2);
    --select--background-color: none;
    --column-drop-container--background: none;
    --warning--background: #61656e;
    --warning--color: white;
    --overflow-hint-icon--color: #ffffff;
  }

  .medusa {
    .ant-select span {
      color: white;
    }

    &.ant-select-dropdown {
      background-color: #1f1f1f;

      .ant-select-item-option-content {
        color: white;
      }
    }

    .ant-select-item.ant-select-item-option-selected {
      background-color: black;
    }

    .toolbar-header {
      color: white;
    }

    .ant-modal-content,
    .ant-modal-header,
    .ant-picker {
      &,
      textarea,
      input {
        background-color: #1f1f1f;
        color: white;
      }

      label,
      .ant-modal-title,
      .ant-modal-close-button {
        color: white;
      }
    }

    .empty-card,
    .empty-card .ant-card-body {
      border-radius: 0px !important;
    }

    .ant-popover .ant-popover-inner,
    .ant-popover .ant-popover-inner-content {
      background-color: #1f1f1f;
      color: white;
    }

    .ant-empty-description {
      color: white;
    }
  }

  .ant-layout-sider-children .ant-menu-inline-collapsed span.ant-menu-title-content {
    border-radius: 8px;
  }
}
