.inputContainer {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 6px;
}

.inputContainer :global(input) {
  font-size: 21px;
}

.inputContainer :global(label) {
  font-size: 21px;
  line-height: 42px;
}

.inputTextbox {
  width: 50%;
}

/*.inputContainer :global(.searchButton) {*/
/*    height: 42px;*/
/*    width: 60px;*/
/*}*/

.cardContainer {
  display: flex;
  column-count: 3;
  flex-wrap: wrap;
}

.cardContainerItem {
  width: 33%;
  min-width: 33%;
}

.cardContainer :global(.ant-card) {
  margin: 10px;
}

.cardContainer :global(.ant-card-body) {
  height: 300px;
  min-height: 300px;
  overflow-y: scroll;
  overflow-x: scroll;
}

.cardContainer :global(.ant-card-head-title) {
  font-weight: bold;
  text-align: center;
}

.cardContainer :global(.no-items) {
  text-align: center;
  font-style: italic;
  margin-top: 14px;
}

.cardContainer :global(.result-container) :global(.ant-menu) {
  max-height: 400px;
  height: 400px;
  overflow-y: scroll;
  width: 100%;
}
