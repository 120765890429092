@value sm-down, md-down from "./breakpoints.module.css";

.pageHeader {
  display: flex;
  justify-content: space-between;
}

.pageHeader > h1 {
  justify-content: center;
  font-size: 32px !important;
}

.pageHeader > * {
  display: flex;
  flex: 1;
}

.pageHeader > :first-child {
  justify-content: start;
}

.pageHeader > :last-child > :not(:last-child) {
  margin-right: 8px;
}

.pageHeaderRight {
  justify-content: end;
  margin-bottom: 5px;
}

@media (sm-down) {
  .pageHeader > h1 {
    font-size: 28px !important;
  }
}
