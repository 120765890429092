.visibilityHidden {
  visibility: hidden;
}

.positionRelative {
  position: relative;
}

.flexGrow1 {
  flex-grow: 1;
}

.timePickerPosition {
  position: absolute !important;
  width: calc(100% - 5px);
  left: 0px;
  top: 0px;
  display: flex;
}

.invalidTime {
  border-color: #ff4d4f !important;
}

.outline {
  border: 1px solid #d9d9d9; /*--gray-5 in zoe*/
  border-radius: 8px;
}

.outline :global(input) {
  border-radius: 8px;
}

.exclamationMarkIcon {
  position: absolute;
  right: 5%;
  top: 30%;
  color: #ff4d4f !important;
}

:global(.clock-dark-customized) {
  color: transparent !important;
}

.inputMask {
  height: 100%;
  width: 100%;
  padding-left: 10px;
  border: none !important;
  outline: none;
  box-sizing: border-box;
}

.backgroundTimePicker {
  z-index: -1;
  border-color: transparent;
  width: calc(100% - 51px);
}
