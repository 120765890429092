@import './colour-palette.css';

@small-border-radius: 2px;
@large-border-radius: 8px;

.light {
  /* ant component overrides */

  background-color: var(--gray-2);

  .ant-drawer-header-close-only {
    padding-bottom: 16px;
  }

  &.mobile .ant-drawer-body {
    padding: 0;
  }

  .ant-modal-footer .ant-btn-default:hover {
    border-color: var(--blue-6);
    color: var(--blue-6);
  }

  .ant-menu-item > span > div > a {
    color: var(--gray-5);
  }

  .ant-menu-item .sidebar-nav-item-service-status {
    color: var(--gray-13) !important;
  }

  .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled {
    color: var(--gray-6);
  }

  .ant-radio-button-wrapper-checked:not(.light .ant-radio-button-wrapper-disabled):active,
  .ant-radio-button-wrapper-checked:not(.light .ant-radio-button-wrapper-disabled):hover {
    color: var(--gray-1);
  }

  li.ant-menu-submenu span.ant-menu-title-content button {
    color: var(--gray-5);
    opacity: 0.6;
    transition: all 0.3s;
    border-radius: @large-border-radius;
  }

  .ant-menu-light .ant-menu-submenu-title button:hover {
    opacity: 1;
  }

  .ant-menu {
    .ant-menu-submenu.sidenavButtonPressed a button,
    .ant-menu-submenu.sidenavButtonPressed a button svg {
      opacity: 1;
      background-color: var(--gray-28);
    }

    :not(.sidenavButtonPressed):not(.ant-menu-submenu-selected):not(.ant-menu-submenu-open) .ant-menu-submenu-title {
      & > i.ant-menu-submenu-arrow {
        opacity: 0.6;
      }

      &:hover > i.ant-menu-submenu-arrow {
        opacity: 1;
        background-color: var(--gray-28);
        border-radius: @large-border-radius;
      }
    }

    :not(.sidenavButtonPressed):not(.ant-menu-submenu-selected) .ant-menu-submenu-title {
      &:hover:not(:has(span.ant-menu-title-content button:hover)) i.ant-menu-submenu-arrow {
        opacity: 1;
        background-color: var(--gray-28);
      }
    }

    :not(.ant-menu-inline-collapsed) {
      .ant-menu-submenu-title {
        & > i.ant-menu-submenu-arrow {
          border-radius: 2px;
        }
      }
    }
  }

  .sidebar-nav-item-title-icon-container a,
  .sidebar-nav-item-title-icon-container a:active,
  .sidebar-nav-item-title-icon-container a:hover {
    color: var(--gray-13);
  }

  .ant-menu-item-selected > span > div > a,
  .ant-menu-item-active > span > div > a,
  .ant-menu-item-active > span > div > a:hover {
    color: var(--gray-13);
  }

  .ant-menu-item a,
  .ant-menu-item a:hover,
  .ant-menu-item .sidebar-nav-item-service-status,
  .ant-menu-item-selected,
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: var(--gray-13);
  }

  //.ant-menu-item a,
  //.ant-menu-item .sidebar-nav-item-service-status {
  //  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
  //  color: var(--gray-1);
  //  opacity: 0.7;
  //}

  // .ant-menu-item a:hover,
  .ant-menu-item-selected,
    //.ant-menu-item-selected a,
    //.ant-menu-item-selected a:hover,
  .ant-menu-item-selected .sidebar-nav-item-service-status,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    opacity: 1;
    background-color: var(--gray-4);
    //border: 1px solid rgb(156, 193, 245);
    color: var(--gray-13);
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    border: 1px solid transparent;
  }

  .ant-menu-item-active,
  .ant-menu-item-active:active,
  .ant-menu-item-active:hover,
  .ant-menu-light .ant-menu-submenu-title:active,
  .ant-menu-light .ant-menu-submenu-title:hover,
  .ant-menu-light .ant-menu-submenu.sidenavButtonPressed :not(ul),
  .ant-menu-light .ant-menu-item:hover,
  .ant-menu-light .ant-menu-item:active,
  .ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):hover {
    background-color: rgba(0, 0, 0, 0.06);
    color: var(--gray-13);
    /* dwt - not sure of this at the moment */
    //border: 1px solid var(--blue-6);
  }

  .ant-menu-light .ant-menu-submenu.sidenavButtonPressed {
    li,
    li * {
      background-color: transparent;
    }
  }

  .ant-menu-item-selected::after {
    display: none;
  }

  .ant-layout-content {
    //DWT: stub - still thinking about what to do with these controls.
  }

  .ant-menu-item > span > div > a {
    color: var(--gray-13);
  }

  .ant-menu-vertical .ant-menu-submenu-selected {
    background-color: var(--gray-4);
  }

  /**
      Mobile Menu
    */

  .ant-modal-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid var(--gray-5);
  }

  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-footer {
    border-top: 1px solid var(--gray-5);
  }

  .ant-drawer-header {
    background: none;
    border-bottom: 1px solid var(--gray-5);
  }

  .ant-drawer-wrapper-body {
    background-color: var(--gray-2);
  }

  .ant-divider {
    border-top: 1px solid var(--gray-27);
  }

  /* --- list --- */

  .ant-list {
    background-color: var(--gray-1);
    border: 1px solid var(--gray-5);
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-list:not(.ant-select-disabled):hover {
    border-color: var(--blue-4);
    border-right-width: 1px;
  }

  .ant-list-item {
    padding: 12px;
  }

  /* --- end list --- */

  .ant-tooltip-inner {
    background-color: var(--gray-13);
  }

  .ant-select-focused {
    border-color: var(--gray-4);
  }

  .ant-progress {
    border-radius: 8px;
  }

  .ant-progress-inner {
    border-radius: 8px;
    border: 1px solid var(--blue-5);
  }

  .ant-progress-success-bg,
  .ant-progress-bg {
    border: 1px solid var(--blue-5);
    border-radius: 4px;
  }

  .ant-menu-vertical {
    color: var(--gray-13);
  }

  .ant-menu-submenu-selected,
  li.ant-menu-submenu,
  span.ant-menu-title-content button,
  li.ant-menu-submenu span.ant-menu-title-content button {
    color: var(--gray-13);
  }

  .ant-menu-submenu-popup > ul.ant-menu {
    background-color: var(--gray-2);
  }

  .ant-empty-description {
    color: var(--gray-21) !important;
  }

  .ant-btn-dangerous:not(.ant-btn-primary):not([disabled]) {
    color: var(--red-8);
    border-color: var(--red-8);
  }

  /* end ant component overrides */

  /* custom components */

  .customize-menu-disable-btn > .ant-menu-item-disabled,
  .customize-dropdown-disable-btn > .ant-dropdown-menu-item-disabled {
    color: var(--gray-21) !important;
  }

  .app-header-container h1,
  .secondary-app-header-container h1 {
    color: var(--gray-13) !important;
  }

  .sidebar-nav-item-title-icon-container button,
  .sidebar-nav-item-title-icon-container button span {
    color: var(--gray-13);
  }

  .global-sidebar-user-info-icons > button,
  .global-sidebar-user-info-icons > button:active,
  .global-sidebar-user-info-icons > button:hover,
  .global-sidebar-user-info-icons > button:focus {
    background-color: transparent;
    border: 0;
  }

  /**
      Header
    */

  .ant-layout-header button,
  .global-search-input button {
    border: none;
    box-shadow: none;
    background: transparent;

    &:hover,
    &:focus {
      background: transparent;
    }
  }

  .global-search-input input {
    color: var(--gray-1);
  }

  .mobile-header {
    background-color: var(--gray-2) !important;
  }

  .mobile-header .anticon-search,
  .mobile-header .header-menu-btn {
    color: var(--gray-13);
  }

  .mobile-header-title {
    color: var(--gray-13);
  }

  .ant-drawer-header-title span {
    color: var(--gray-13);
  }

  .global-search-input input,
  .global-search-input .ant-input-group-addon {
    background-color: transparent;
  }

  .ant-drawer-close,
  .header-menu-btn,
  .mobile-header-search-btn {
    color: var(--gray-1);
  }

  .global-search-input .ant-input-search-button {
    color: var(--gray-1) !important;
  }

  .customize-menu-disable-btn > .ant-menu-item-disabled {
    color: var(--gray-5) !important;
  }

  .generic-popup-menu,
  .generic-popup-menu.ant-menu-root.ant-menu-vertical {
    box-shadow: 0 3px 6px -4px var(--gray-26),
    0 6px 16px 0 var(--gray-27),
    0 9px 28px 8px var(--gray-28);
  }

  .generic-popup-menu .ant-menu-item-selected {
    color: var(--gray-13);
  }

  /* --- scrollbar colouring --- */

  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background: var(--gray);
    border-radius: 50px;
    opacity: 0.5;

    &:hover {
      background: var(--gray);
    }
  }

  /* --- end of scrollbar colouring --- */

  .fileBadge {
    background-color: var(--gray-1);
  }

  .caption {
    color: var(--gray-13);
  }

  .pwa-header {
    color: var(--gray-13);
  }

  /* --- a11y colors fixes --- */

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--gray-21);
  }

  .ant-form-item-extra {
    color: var(--gray-21);
  }

  .ant-form-item-explain-success {
    color: var(--gray-21);
  }

  .ant-form-item-explain.ant-form-item-explain-connected {
    color: var(--gray-21);
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--blue-7);
  }

  .ant-picker-input > input::placeholder {
    color: var(--gray-20);
  }

  .ant-select-selection-placeholder {
    color: var(--gray-21);
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: var(--gray-21) !important;
  }

  .ant-btn-link {
    color: var(--blue-7);
  }

  .ant-btn-primary[disabled] {
    background-color: var(--gray-2);
  }

  :not(.sidenav) button.ant-btn:focus:not(.ant-btn-primary):not(.ant-btn-dangerous):not(:disabled),
  :not(.sidenav) button.ant-btn:hover:not(.ant-btn-primary):not(.ant-btn-dangerous):not(:disabled) {
    color: var(--blue-6);
    /* dwt - not sure of this at the moment */
    //border: 1px solid var(--blue-6);
  }

  :not(.sidenav) button.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--blue-7);
  }

  a:hover {
    color: var(--blue-7);
  }

  .ant-list-item-meta-description {
    color: var(--gray-21) !important;
  }

  /* --- end a11y colors fix --- */

  .anticon-folder,
  .anticon-file-text,
  .ant-menu-submenu-title,
  .ant-menu-item-selected {
    color: var(--gray-13) !important;
  }

  .ant-collapse-content-box {
    border-top: 1px solid var(--gray-5);
  }

  // Github light flavored syntax highlighting for .markdown-body class
  @import (less) 'github-markdown-css/github-markdown-light.css';

  .markdown-body {
    @import (less) 'highlight.js/styles/github.css';
  }

  .ant-divider.ant-divider-horizontal.ant-divider-with-text {
    border-top: 0 !important;
    border-top-color: var(--gray-28) !important;
  }

  .ant-menu-light.ant-menu {
    background: transparent;
  }

  .ant-modal .ant-collapse.infoCollapse {
    background-color: var(--gray-2);
  }

  .pageTitleWithInfoIcon {
    display: flex;
  }

  .infoIcon {
    border: none;
    box-shadow: none;
    background: transparent;
  }

  .infoIcon:hover {
    cursor: pointer;
  }

  .ant-form-item-has-error .ant-picker {
    border: 1px solid var(--red-5);
  }

  .ant-form-item-has-error .ant-picker-input input {
    border: none;
  }

  .ant-layout-sider-children .ant-menu-inline-collapsed span.ant-menu-title-content {
    border-radius: 8px;
  }

  .side-menu-bottom {
    background-color: var(--gray-2);
  }

  /* perspective light theme fixes */

  :root & {
    --icon--color: #161616;
    --inactive--color: #ababab;
    --inactive--border-color: #dadada;
    --root--background: #ffffff;
    --active--color: #2670a9;
    --error--color: #ff471e;
    --plugin--background: #ffffff;
    --overflow-hint-icon--color: rgba(0, 0, 0, 0.2);
    --select--background-color: none;
    --column-drop-container--background: none;
    --warning--background: #042121;
    --warning--color: #fdfffd;
    --overflow-hint-icon--color: #fdfffd;
  }
}

.recharts-default-tooltip {
  border: 1px solid var(--gray-9);
  box-shadow: 0 0 24px var(--gray-2);
}
